var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "accounts-payable-index"
    }
  }, [_vm.roleMama ? _c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v(_vm._s(_vm.$t('accountsPayable.title')))])];
      },
      proxy: true
    }], null, false, 3854697956)
  }, [_c('AccountsPayableList')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v(_vm._s(_vm.$t('accountsPayable.f2.title')))])];
      },
      proxy: true
    }], null, false, 4013017182)
  }, [_c('AccountsPayableF2List')], 1)], 1) : _c('AccountsPayableList')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }