var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "accounts-payable-filters"
    }
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-between gap-2 mb-50",
    class: _vm.isMobileView ? 'flex-wrap' : ''
  }, [_c('b-row', {
    staticClass: "w-100",
    class: _vm.isMobileView ? 'mx-0' : ''
  }, [_c('b-col', {
    class: _vm.isMobileView ? 'mb-75' : '',
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h6",
      "label": _vm.$t('topup.startDate'),
      "label-for": "startDate"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "id": "startDate",
      "value": _vm.fromDateFilter,
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        maxDate: _vm.maxStartDate,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    on: {
      "update:value": function updateValue($event) {
        _vm.fromDateFilter = $event;
      },
      "input": function input($event) {
        return _vm.$emit('update:fromDateFilter', $event);
      }
    }
  })], 1)], 1), _c('b-col', {
    class: _vm.isMobileView ? 'mb-75' : '',
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h6",
      "label": _vm.$t('topup.endDate'),
      "label-for": "endDate"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "id": "endDate",
      "value": _vm.toDateFilter,
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        minDate: _vm.minEndDate,
        maxDate: _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    on: {
      "update:value": function updateValue($event) {
        _vm.toDateFilter = $event;
      },
      "input": function input($event) {
        return _vm.$emit('update:toDateFilter', $event);
      }
    }
  })], 1)], 1), _c('b-col', {
    class: _vm.isMobileView ? 'mb-75' : '',
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h6",
      "label-for": "agencyCode",
      "label": _vm.$t('agency.agencyCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "agencyCode",
      "value": _vm.agencyCodeListFilter,
      "placeholder": _vm.$t('saleReport.phAgencyCode'),
      "formatter": _vm.upperCaseFormatter
    },
    on: {
      "update:value": function updateValue($event) {
        _vm.agencyCodeListFilter = $event;
      },
      "input": function input($event) {
        _vm.$emit('update:agencyCodeListFilter', $event.trim());
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h6",
      "label-for": "customerCode",
      "label": _vm.$t('customer.code')
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "customerCode",
      "value": _vm.customerCodeListFilter,
      "placeholder": _vm.$t('customer.placeholderCode'),
      "formatter": _vm.upperCaseFormatter
    },
    on: {
      "update:value": function updateValue($event) {
        _vm.customerCodeListFilter = $event;
      },
      "input": function input($event) {
        _vm.$emit('update:customerCodeListFilter', $event.trim());
      }
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex align-items-start gap-1",
    class: _vm.isMobileView ? 'w-100 align-items-center justify-content-center' : ''
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-gradient text-nowrap",
    attrs: {
      "pill": "",
      "disabled": !_vm.fromDateFilter || !_vm.toDateFilter
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('refetch-data');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "SearchIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v(_vm._s(_vm.$t('search')))])], 1), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-white text-nowrap",
    attrs: {
      "pill": "",
      "disabled": _vm.isEmptyFilter
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('clear-filter');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    staticStyle: {
      "color": "cadetblue"
    },
    attrs: {
      "icon": "RefreshCwIcon"
    }
  }), _c('span', {
    staticClass: "align-middle text-gradient"
  }, [_vm._v(_vm._s(_vm.$t('clear')) + " ")])], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-0"
  }, [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('accountsPayable.closingBalance'),
      "label-for": "closingBalance",
      "label-class": "h6"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "closingBalance",
      "value": _vm.formatCurrency(_vm.totalClosingBalance),
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('accountsPayable.tradeCreditors'),
      "label-for": "tradeCreditors",
      "label-class": "h6"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "tradeCreditors",
      "value": _vm.formatCurrency(_vm.totalTradeCreditor),
      "disabled": ""
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }