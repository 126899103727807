var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "accounts-payable-f2-filters"
    }
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-between gap-2 mb-50"
  }, [_c('b-row', {
    staticClass: "w-100"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h6",
      "label": _vm.$t('topup.startDate'),
      "label-for": "startDate"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "id": "startDate",
      "value": _vm.fromDateFilter,
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        maxDate: _vm.maxStartDate,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    on: {
      "update:value": function updateValue($event) {
        _vm.fromDateFilter = $event;
      },
      "input": function input($event) {
        return _vm.$emit('update:fromDateFilter', $event);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h6",
      "label": _vm.$t('topup.endDate'),
      "label-for": "endDate"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "id": "endDate",
      "value": _vm.toDateFilter,
      "placeholder": _vm.$t('topup.placeholderSelectDate'),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        minDate: _vm.minEndDate,
        maxDate: _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    on: {
      "update:value": function updateValue($event) {
        _vm.toDateFilter = $event;
      },
      "input": function input($event) {
        return _vm.$emit('update:toDateFilter', $event);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-class": "h6",
      "label-for": "agencyCode",
      "label": _vm.$t('agency.agencyCode')
    }
  }, [_c('v-select', {
    attrs: {
      "id": "agencyCode",
      "options": _vm.agencyOptions,
      "label": "agencyCode",
      "searchable": "",
      "clearable": "",
      "filterable": false,
      "value": _vm.agencyListFilter,
      "loading": _vm.loading,
      "placeholder": _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val;
      }
    },
    on: {
      "update:value": function updateValue($event) {
        _vm.agencyListFilter = $event;
      },
      "open": _vm.handleOpenAgency,
      "search": _vm.handleSearchAgency,
      "input": function input(val) {
        return _vm.$emit('update:agencyListFilter', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref) {
        var agencyName = _ref.agencyName,
          agencyCode = _ref.agencyCode;
        return [_c('div', {
          staticStyle: {
            "width": "200px"
          }
        }, [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate"
        }, [_vm._v(" " + _vm._s(agencyName) + " "), _c('small', [_vm._v("(" + _vm._s(agencyCode) + ")")])])])];
      }
    }, {
      key: "option",
      fn: function fn(_ref2) {
        var agencyName = _ref2.agencyName,
          agencyCode = _ref2.agencyCode;
        return [_c('div', {
          staticStyle: {
            "width": "200px"
          }
        }, [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate"
        }, [_vm._v(" " + _vm._s(agencyName) + " "), _c('small', [_vm._v("(" + _vm._s(agencyCode) + ")")])])])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref3) {
        var loading = _ref3.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex align-items-start gap-1"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-gradient text-nowrap",
    attrs: {
      "pill": "",
      "disabled": !_vm.agencyListFilter || !_vm.fromDateFilter || !_vm.toDateFilter
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('fetch-accounts-payable-f2');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "SearchIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v(_vm._s(_vm.$t('search')))])], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-0"
  }, [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('accountsPayable.closingBalance'),
      "label-for": "closingBalance",
      "label-class": "h6"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "closingBalance",
      "value": _vm.formatCurrency(_vm.totalClosingBalance),
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('accountsPayable.tradeCreditors'),
      "label-for": "tradeCreditors",
      "label-class": "h6"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "tradeCreditors",
      "value": _vm.formatCurrency(_vm.totalTradeCreditor),
      "disabled": ""
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }