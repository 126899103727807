var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "mb-1 box-shadow-0 border p-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('AccountsPayableF2Filters', {
    attrs: {
      "from-date-filter": _vm.fromDateFilter,
      "agency-list-filter": _vm.agencyListFilter,
      "to-date-filter": _vm.toDateFilter,
      "total-opening-balance": _vm.totalOpeningBalance,
      "total-closing-balance": _vm.totalClosingBalance,
      "total-trade-creditor": _vm.totalTradeCreditor,
      "total-receivables": _vm.totalReceivables
    },
    on: {
      "update:fromDateFilter": function updateFromDateFilter($event) {
        _vm.fromDateFilter = $event;
      },
      "update:from-date-filter": function updateFromDateFilter($event) {
        _vm.fromDateFilter = $event;
      },
      "update:agencyListFilter": function updateAgencyListFilter($event) {
        _vm.agencyListFilter = $event;
      },
      "update:agency-list-filter": function updateAgencyListFilter($event) {
        _vm.agencyListFilter = $event;
      },
      "update:toDateFilter": function updateToDateFilter($event) {
        _vm.toDateFilter = $event;
      },
      "update:to-date-filter": function updateToDateFilter($event) {
        _vm.toDateFilter = $event;
      },
      "fetch-accounts-payable-f2": _vm.fetchAccountPayableF2
    }
  }), !_vm.itemsAccountPayableF2ListTable || !_vm.agencyListFilter || !_vm.fromDateFilter || !_vm.toDateFilter ? _c('div', [_c('b-alert', {
    staticClass: "px-1 py-1 my-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Chọn ngày và đại lý để xem ")])], 1) : _c('div', [_c('div', {
    staticClass: "my-50"
  }, [_c('b-row', {
    staticClass: "justify-content-between flex-md-nowrap",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('span', {
    staticClass: "text-muted text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.total')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.items')))])]), _c('b-col', {
    staticClass: "d-flex justify-content-end px-50",
    attrs: {
      "cols": "8",
      "md": "auto",
      "order-md": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "px-lg-1 px-sm-75 py-50",
    attrs: {
      "variant": "flat-info"
    },
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('img', {
    staticClass: "cursor-pointer",
    attrs: {
      "src": require("@icons/export-excel.svg"),
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline ml-50"
  }, [_vm._v(_vm._s(_vm.$t('export')))])])])], 1)])], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('b-table', {
    ref: "refAccountPayableF2ListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "calc(100vh - 350px)"
    },
    attrs: {
      "sticky-header": "",
      "items": _vm.itemsAccountPayableF2ListTable,
      "responsive": "",
      "fields": _vm.tableListColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy,
      "no-border-collapse": ""
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableListColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: column.label,
            staticClass: "text-dark text-nowrap d-flex-center"
          }, [_vm._v(" " + _vm._s(_vm.$t(column.label === 'receivables' ? 'accountsPayable.receivablesTitle' : "accountsPayable.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(agCode)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info",
          attrs: {
            "to": {
              name: 'apps-accountsPayable-detail-f2',
              params: {
                id: Number(_vm.agencyListFilter.id)
              },
              query: {
                code: data.item.code,
                typeCode: data.item.type,
                startDate: _vm.convertISODateTime(_vm.fromDateFilter).dateFilter || _vm.convertISODateTime(new Date()).dateFilter,
                endDate: _vm.convertISODateTime(_vm.toDateFilter).dateFilter || _vm.convertISODateTime(new Date()).dateFilter
              }
            }
          }
        }, [_c('span', {
          staticClass: "align-text-top text-capitalize font-weight-bold"
        }, [_vm._v(" " + _vm._s(data.item.code) + " ")])])], 1)];
      }
    }], null, true)
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }