<template>
  <div>
    <b-card
      no-body
      class="mb-1 box-shadow-0 border p-1"
    >
      <AccountsPayableF2Filters
        :from-date-filter.sync="fromDateFilter"
        :agency-list-filter.sync="agencyListFilter"
        :to-date-filter.sync="toDateFilter"
        :total-opening-balance="totalOpeningBalance"
        :total-closing-balance="totalClosingBalance"
        :total-trade-creditor="totalTradeCreditor"
        :total-receivables="totalReceivables"
        @fetch-accounts-payable-f2="fetchAccountPayableF2"
      />
      <div v-if="!itemsAccountPayableF2ListTable || !agencyListFilter || !fromDateFilter || !toDateFilter">
        <b-alert
          show
          variant="warning"
          class="px-1 py-1 my-1"
        >
          Chọn ngày và đại lý để xem
        </b-alert>
      </div>
      <div v-else>
        <div class="my-50">
          <b-row
            align-v="center"
            class="justify-content-between flex-md-nowrap"
          >
            <b-col
              cols="12"
              md="auto"
            >
              <span class="text-muted text-nowrap">
                {{ $t('paginationText.total') }} {{ dataMeta.to }} {{ $t('paginationText.items') }}</span>
            </b-col>
            <b-col
              cols="8"
              md="auto"
              class="d-flex justify-content-end px-50"
              order-md="3"
            >
              <div class="d-flex">
                <b-button
                  class="px-lg-1 px-sm-75 py-50"
                  variant="flat-info"
                  @click="confirmExport()"
                >
                  <span
                    class="text-nowrap"
                  >
                    <img
                      src="@icons/export-excel.svg"
                      class="cursor-pointer"
                      size="16"
                    >
                    <span class="d-none d-sm-inline ml-50">{{ $t('export') }}</span>
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-overlay
          :show="loading"
          rounded="sm"
          no-fade
          variant="transparent"
          :opacity="0.5"
        >
          <b-table
            ref="refAccountPayableF2ListTable"
            style="max-height: calc(100vh - 350px)"
            sticky-header
            class="position-relative"
            :items="itemsAccountPayableF2ListTable"
            responsive
            :fields="tableListColumns"
            primary-key="id"
            show-empty
            :empty-text="$t('noMatchingResult')"
            :sort-desc.sync="isSortDirDesc"
            :sort-by.sync="sortBy"
            no-border-collapse
          >

            <template
              v-for="column in tableListColumns"
              v-slot:[`head(${column.key})`]="data"
            >
              <div
                :key="column.label"
                class="text-dark text-nowrap d-flex-center"
              >
                {{ $t(column.label === 'receivables' ? 'accountsPayable.receivablesTitle' :`accountsPayable.${data.label}`) }}
              </div>
            </template>

            <template #cell(no)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(agCode)="data">
              <div class="text-nowrap">
                <b-link
                  :to="{
                    name: 'apps-accountsPayable-detail-f2',
                    params: {
                      id: Number(agencyListFilter.id)
                    },
                    query: {
                      code: data.item.code,
                      typeCode: data.item.type,
                      startDate: convertISODateTime(fromDateFilter).dateFilter || convertISODateTime(new Date()).dateFilter,
                      endDate: convertISODateTime(toDateFilter).dateFilter || convertISODateTime(new Date()).dateFilter,
                    },
                  }"
                  class="font-weight-bold d-block text-nowrap text-info"
                >
                  <span class="align-text-top text-capitalize font-weight-bold">
                    {{ data.item.code }}
                  </span>
                </b-link>
              </div>
            </template>
            <!--
            <template #cell(agName)="data">
              <div class="font-weight-bold align-text-center mb-0">
                {{ data.item.name || '' }}
              </div>

            </template> -->

            <!-- <template #cell(openingBalance)="data">
              <div class="text-right">
                {{ formatCurrency(data.item.openingBalance) }}
              </div>
            </template>

            <template #cell(closingBalance)="data">
              <div class="text-right">
                {{ formatCurrency(data.item.closingBalance) }}
              </div>
            </template>

            <template #cell(tradeCreditors)="data">
              <div class="text-right">
                {{ formatCurrency(data.item.tradeCreditors) }}
              </div>
            </template>

            <template #cell(receivables)="data">
              <div class="text-right">
                {{ formatCurrency(data.item.receivables) }}
              </div>
            </template>

            <template #cell(paid)="data">
              <div class="text-right">
                {{ formatCurrency(data.item.paid) }}
              </div>
            </template> -->
          </b-table>
        </b-overlay>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BOverlay, BAlert,
} from 'bootstrap-vue'
import {
  onUnmounted,
} from '@vue/composition-api'

import store from '@/store'
import { sizePerPageOptions } from '@/constants/selectOptions'
import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'

import accountsPayableF2StoreModule from '@accountsPayable/accounts-payable-f2/accountsPayableF2StoreModule'
import useAccountsPayableF2Handle from '@accountsPayable/accounts-payable-f2/useAccountsPayableF2Handle'

import AccountsPayableF2Filters from './AccountsPayableF2Filters.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BOverlay,
    BAlert,

    AccountsPayableF2Filters,
  },
  setup() {
    const ACCOUNTS_PAYABLE_F2_APP_STORE_MODULE_NAME = 'app-account-payable-f2'

    // Register module
    if (!store.hasModule(ACCOUNTS_PAYABLE_F2_APP_STORE_MODULE_NAME)) {
      store.registerModule(ACCOUNTS_PAYABLE_F2_APP_STORE_MODULE_NAME, accountsPayableF2StoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTS_PAYABLE_F2_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(ACCOUNTS_PAYABLE_F2_APP_STORE_MODULE_NAME)
      }
    })

    const {
      fetchAccountPayableF2,
      refAccountPayableF2ListTable,
      tableListColumns,
      fromDateFilter,
      toDateFilter,
      agencyListFilter,

      dataMeta,
      sortBy,
      isSortDirDesc,
      loading,
      totalClosingBalance,
      totalOpeningBalance,
      totalTradeCreditor,
      totalReceivables,
      exportAccountPayableListF2,
      itemsAccountPayableF2ListTable,
    } = useAccountsPayableF2Handle()

    function confirmExport() {
      // show modal confirm export sale report
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmExport') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            exportAccountPayableListF2()
          }
        })
    }
    return {
      itemsAccountPayableF2ListTable,
      totalClosingBalance,
      totalOpeningBalance,
      totalTradeCreditor,
      totalReceivables,
      loading,
      sizePerPageOptions,
      dataMeta,
      tableListColumns,
      sortBy,
      isSortDirDesc,
      fromDateFilter,
      toDateFilter,
      agencyListFilter,
      formatCurrency,

      refAccountPayableF2ListTable,
      convertISODateTime,

      confirmExport,
      fetchAccountPayableF2,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector ::v-deep {
  width: 100%;
  max-width: 80px;

  .vs__selected-options {
    flex-wrap: nowrap
  }
}
</style>
