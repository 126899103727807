<template>
  <b-modal
    id="book-closing-modal"
    header-bg-variant="light-info"
    centered
    size="md"
  >
    <template v-slot:modal-header>
      <h4 class="text-heading-3 m-0">
        {{ $t('accountsPayable.bookClosing') }}
      </h4>
    </template>

    <template
      v-slot:modal-footer
    >
      <b-button
        variant="outline-danger"
        pill
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="cancelHandle"
      >
        {{ $t('reservation.cancel') }}
      </b-button>
      <b-button
        variant="gradient"
        pill
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="!date"
        @click="saveHandle"
      >
        {{ $t('accountsPayable.save') }}
      </b-button>
    </template>

    <b-form
      ref="form"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group class="my-0">
            <template #label>
              <div class="text-heading-4">
                {{ $t('accountsPayable.date') }}
                <!-- <span class="text-danger">(*)</span> -->
              </div>
            </template>
            <b-form-group
              label-class="h5"
              :label="$t('topup.startDate')"
            >
              <b-form-datepicker
                v-model="date"
                style="font-size: 1rem;"
                reset-button
                close-button
                :locale="$i18n.locale"
                :max="new Date()"
                :placeholder="$t('topup.placeholderSelectDate')"
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }"
              />
            </b-form-group>

            <b-alert
              show
              variant="info"
              class="my-0 mt-1 px-1 py-50"
            >
              Đã khoá tới ngày {{ dateBookClosing }}
            </b-alert>
          </b-form-group>
        </b-col>
      </b-row>

    </b-form>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BFormGroup, BRow, BCol, BForm, BAlert,
  BFormDatepicker,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BAlert,
    BForm,
    BFormDatepicker,
  },

  props: {
    dateBookClosing: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const date = ref()

    function saveHandle() {
      emit('date-book-close', date.value)
      this.$bvModal.hide('book-closing-modal')
    }

    function cancelHandle() {
      this.$bvModal.hide('book-closing-modal')
    }

    return {
      date,
      saveHandle,
      cancelHandle,
      Vietnamese,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
