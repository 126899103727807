var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "mb-1 box-shadow-0 border p-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('AccountsPayableFilters', {
    attrs: {
      "from-date-filter": _vm.fromDateFilter,
      "customer-code-list-filter": _vm.customerCodeListFilter,
      "agency-code-list-filter": _vm.agencyCodeListFilter,
      "to-date-filter": _vm.toDateFilter,
      "is-empty-filter": _vm.isEmptyFilter,
      "total-opening-balance": _vm.totalOpeningBalance,
      "total-closing-balance": _vm.totalClosingBalance,
      "total-trade-creditor": _vm.totalTradeCreditor,
      "total-receivables": _vm.totalReceivables
    },
    on: {
      "update:fromDateFilter": function updateFromDateFilter($event) {
        _vm.fromDateFilter = $event;
      },
      "update:from-date-filter": function updateFromDateFilter($event) {
        _vm.fromDateFilter = $event;
      },
      "update:customerCodeListFilter": function updateCustomerCodeListFilter($event) {
        _vm.customerCodeListFilter = $event;
      },
      "update:customer-code-list-filter": function updateCustomerCodeListFilter($event) {
        _vm.customerCodeListFilter = $event;
      },
      "update:agencyCodeListFilter": function updateAgencyCodeListFilter($event) {
        _vm.agencyCodeListFilter = $event;
      },
      "update:agency-code-list-filter": function updateAgencyCodeListFilter($event) {
        _vm.agencyCodeListFilter = $event;
      },
      "update:toDateFilter": function updateToDateFilter($event) {
        _vm.toDateFilter = $event;
      },
      "update:to-date-filter": function updateToDateFilter($event) {
        _vm.toDateFilter = $event;
      },
      "refetch-data": _vm.refetchData,
      "clear-filter": _vm.clearFilter
    }
  }), _c('div', {
    staticClass: "my-50"
  }, [_c('b-row', {
    staticClass: "justify-content-between flex-md-nowrap",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('span', {
    staticClass: "text-muted text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.total')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.items')))])]), _c('b-col', {
    staticClass: "d-flex justify-content-end px-50",
    attrs: {
      "cols": "8",
      "md": "auto",
      "order-md": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "px-lg-1 px-sm-75 py-50",
    attrs: {
      "variant": "flat-info",
      "disabled": !_vm.canAccess('acc_payable.lockAccpayble')
    },
    on: {
      "click": _vm.openBookClosingModal
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('img', {
    staticClass: "cursor-pointer",
    attrs: {
      "src": require("@icons/book-closing.svg"),
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline ml-50"
  }, [_vm._v(_vm._s(_vm.$t('accountsPayable.bookClosing')))])])]), _c('b-button', {
    staticClass: "px-lg-1 px-sm-75 py-50",
    attrs: {
      "variant": "flat-info",
      "disabled": !_vm.canAccess('sales_report.importSalereport'),
      "to": {
        name: 'apps-accountsPayable-import'
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('img', {
    staticClass: "cursor-pointer",
    attrs: {
      "src": require("@icons/import-excel.svg"),
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline ml-50"
  }, [_vm._v(_vm._s(_vm.$t('import')))])])]), _c('b-button', {
    staticClass: "px-lg-1 px-sm-75 py-50",
    attrs: {
      "variant": "flat-info"
    }
  }, [_c('span', {
    staticClass: "text-nowrap",
    on: {
      "click": function click($event) {
        return _vm.confirmExport();
      }
    }
  }, [_c('img', {
    staticClass: "cursor-pointer",
    attrs: {
      "src": require("@icons/export-excel.svg"),
      "size": "16"
    }
  }), _c('span', {
    staticClass: "d-none d-sm-inline ml-50"
  }, [_vm._v(_vm._s(_vm.$t('export')))])])])], 1)])], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('b-table', {
    ref: "refAccountPayableListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "calc(100vh - 350px)"
    },
    attrs: {
      "sticky-header": "",
      "items": _vm.fetchAccountPayable,
      "responsive": "",
      "fields": _vm.tableListColumns,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy,
      "no-border-collapse": ""
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableListColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: column.label,
            staticClass: "text-dark text-nowrap d-flex-center"
          }, [_vm._v(" " + _vm._s(_vm.$t(column.label === 'receivables' ? 'accountsPayable.receivablesTitle' : "accountsPayable.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(agCode)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info",
          attrs: {
            "to": {
              name: 'apps-accountsPayable-detail',
              query: {
                code: data.item.code,
                typeCode: data.item.type,
                startDate: _vm.convertISODateTime(_vm.fromDateFilter).dateFilter || _vm.convertISODateTime(new Date()).dateFilter,
                endDate: _vm.convertISODateTime(_vm.toDateFilter).dateFilter || _vm.convertISODateTime(new Date()).dateFilter
              }
            }
          }
        }, [_c('span', {
          staticClass: "align-text-top text-capitalize font-weight-bold"
        }, [_vm._v(" " + _vm._s(data.item.code) + " ")])])], 1)];
      }
    }, {
      key: "cell(agName)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "font-weight-bold align-text-center mb-0"
        }, [_vm._v(" " + _vm._s(data.item.name || '') + " ")])];
      }
    }, {
      key: "cell(openingBalance)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.openingBalance)) + " ")])];
      }
    }, {
      key: "cell(closingBalance)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.closingBalance)) + " ")])];
      }
    }, {
      key: "cell(tradeCreditors)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.tradeCreditors)) + " ")])];
      }
    }, {
      key: "cell(receivables)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.receivables)) + " ")])];
      }
    }, {
      key: "cell(paid)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.paid)) + " ")])];
      }
    }], null, true)
  })], 1)], 1), _c('AccountsPayableBookClosingModal', {
    attrs: {
      "date-book-closing": _vm.dateBookClosing
    },
    on: {
      "date-book-close": _vm.bookClose
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }