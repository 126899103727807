<template>
  <div id="accounts-payable-f2-filters">
    <div class="d-flex-center justify-content-between gap-2 mb-50">
      <b-row class="w-100">
        <!-- ANCHOR START DATE -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-class="h6"
            :label="$t('topup.startDate')"
            label-for="startDate"
          >
            <flat-pickr
              id="startDate"
              :value.sync="fromDateFilter"
              class="form-control"
              :placeholder="$t('topup.placeholderSelectDate')"
              :config="{
                allowInput: true,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                altInput: true,
                maxDate: maxStartDate,
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
              @input="$emit('update:fromDateFilter', $event)"
            />
          </b-form-group>
        </b-col>

        <!-- ANCHOR END DATE -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-class="h6"
            :label="$t('topup.endDate')"
            label-for="endDate"
          >
            <flat-pickr
              id="endDate"
              :value.sync="toDateFilter"
              class="form-control"
              :placeholder="$t('topup.placeholderSelectDate')"
              :config="{
                allowInput: true,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                altInput: true,
                minDate: minEndDate,
                maxDate: today,
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
              @input="$emit('update:toDateFilter', $event)"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <!-- ANCHOR AGENCY -->
          <b-form-group
            label-class="h6"
            label-for="agencyCode"
            :label="$t('agency.agencyCode')"
          >
            <v-select
              id="agencyCode"
              :options="agencyOptions"
              label="agencyCode"
              searchable
              clearable
              :filterable="false"
              :value.sync="agencyListFilter"
              :loading="loading"
              :placeholder="$t('placeholderSelect')"
              :reduce="val => val"
              @open="handleOpenAgency"
              @search="handleSearchAgency"
              @input="(val) => $emit('update:agencyListFilter', val)"
            >
              <template #selected-option="{agencyName, agencyCode}">
                <div style="width: 200px">
                  <span
                    class="d-block font-weight-bold text-truncate"
                  >
                    {{ agencyName }} <small>({{ agencyCode }})</small>
                  </span>
                </div>
              </template>
              <template v-slot:option="{agencyName, agencyCode}">
                <div style="width: 200px">
                  <span
                    class="d-block font-weight-bold text-truncate"
                  >
                    {{ agencyName }} <small>({{ agencyCode }})</small>
                  </span>
                </div>
              </template>
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- ANCHOR Buttons -->
      <div class="d-flex align-items-start gap-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient text-nowrap"
          pill
          :disabled="!agencyListFilter || !fromDateFilter || !toDateFilter"
          @click="$emit('fetch-accounts-payable-f2')"
        >
          <feather-icon
            icon="SearchIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('search') }}</span>
        </b-button>
      </div>
    </div>
    <b-row class="mb-0">
      <!-- <b-col>
        <b-form-group
          :label="$t('accountsPayable.openingBalance')"
          label-for="openingBalance"
        >
          <b-form-input
            id="openingBalance"
            :value="formatCurrency(totalOpeningBalance)"
            disabled
          />
        </b-form-group>
      </b-col>-->
      <b-col>
        <b-form-group
          :label="$t('accountsPayable.closingBalance')"
          label-for="closingBalance"
          label-class="h6"
        >
          <b-form-input
            id="closingBalance"
            :value="formatCurrency(totalClosingBalance)"
            disabled
          />
        </b-form-group>
      </b-col>
      <!-- <b-col>
        <b-form-group
          :label="$t('accountsPayable.receivables')"
          label-for="receivables"
          label-class="h6"
        >
          <b-form-input
            id="receivables"
            :value="formatCurrency(totalReceivables)"
            disabled
          />
        </b-form-group>
      </b-col> -->
      <b-col>
        <b-form-group
          :label="$t('accountsPayable.tradeCreditors')"
          label-for="tradeCreditors"
          label-class="h6"
        >
          <b-form-input
            id="tradeCreditors"
            :value="formatCurrency(totalTradeCreditor)"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { computed, ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { debounce } from 'lodash'

import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'

import { upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useAccountsPayableF2Handle from '../useAccountsPayableF2Handle'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,

    flatPickr,
    vSelect,
  },
  props: {
    fromDateFilter: {
      type: String,
      default: '',
    },
    toDateFilter: {
      type: String,
      default: '',
    },
    agencyListFilter: {
      type: Object,
      default: () => {},
    },
    totalOpeningBalance: {
      type: Number,
      default: null,
    },
    totalClosingBalance: {
      type: Number,
      default: null,
    },
    totalTradeCreditor: {
      type: Number,
      default: null,
    },
    totalReceivables: {
      type: Number,
      default: null,
    },
  },

  setup(props) {
    const today = convertISODateTime(new Date()).dateFilter
    const maxStartDate = computed(() => (props.toDateFilter ? props.toDateFilter : today))
    const minEndDate = computed(() => (props.fromDateFilter ? props.fromDateFilter : undefined))
    const { fetchAgencies, loading } = useAccountsPayableF2Handle()
    // ANCHOR fetch Agency
    const agencyOptionsDefault = ref([])
    const agencyOptions = ref([])

    function handleOpenAgency() {
      if (agencyOptionsDefault.value.length === 0) {
        fetchAgencies().then(res => {
          agencyOptions.value = res
          agencyOptionsDefault.value = res
        })
      } else {
        agencyOptions.value = agencyOptionsDefault.value
      }
    }

    const handleSearchAgency = debounce(search => {
      if (search) {
        fetchAgencies(search).then(res => {
          agencyOptions.value = res
        })
      }
    }, 300)

    return {
      Vietnamese,
      formatCurrency,
      maxStartDate,
      minEndDate,
      upperCaseFormatter,
      today,
      handleOpenAgency,
      handleSearchAgency,
      agencyOptions,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
#accounts-payable-f2-filters ::v-deep {
  .form-group {
    margin-bottom: 0;
    label {
      margin-bottom: 2px;
    }
  }
}
</style>
